import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";

const Quote = styled.p`
  font-style: italic;
`;

const SectionAttributes = () => (
  <Flex justifyContent="center">
    <Box width={[1, 1, 2 / 3, 1 / 2]} my={5} px={3}>
      <h3>Was Ihren Immobilienmakler in Rudolstadt ausmachen sollte</h3>
      <Box mb={5} mt={4}>
        <h4>Vertrauen & Empathie</h4>
        <p>
          Ein vertrauenswürder und erfolgreicher Immobilienmakler zeichnet sich durch seine ausgeprägte Empathie aus. Der Makler versteht Ihre Bedürfnisse und Sorgen und kann sich in Ihre Lage versetzen. 
          
          Dank dieser Fähigkeit schaffen wir eine vertrauensvolle Atmosphäre, in der Sie sich wohlfühlen und offen über Ihre Wünsche und Vorstellungen sprechen können.
        </p>

      </Box>
      <Box mb={5}>
        <h4>Kommunikation & Transparenz</h4>
        <p>
        Kommunikationsstärke ist eine weitere wichtige Eigenschaft eines guten Immobilienmaklers. Wir beherrscht die Kunst des klaren und effektiven Kommunizierens, sowohl schriftlich als auch mündlich. Wir möchten dabei ein guter Zuhörer sein und nehmen ins ausreichend Zeit, um Ihre Anliegen und Bedürfnisse zu verstehen. Durch eine offene und transparente Kommunikation schaffen wir eine solide Grundlage für eine erfolgreiche Zusammenarbeit.
        </p>

      </Box>

      <Box mb={5}>
        <h4>Kundenorientiertes Arbeiten</h4>
        <p>
        Neben den genannten Eigenschaften ist eine ausgeprägte Kundenorientierung von großer Bedeutung. Als erfolgreicher Immobilienmakler in Rudolstadt stellen wir die Bedürfnisse und Ziele unserer Kunden stets in den Mittelpunkt. Wir engagieren uns aktiv für ihre Interessen und strebt maßgeschneiderte Lösungen an, die Ihren individuellen Anforderungen gerecht werden.
        </p>

      </Box>

      <Box mb={5}>
        <h4>Erfahrung & Fachwissen</h4>
        <p>
        Ein fundiertes Fachwissen ist unerlässlich für einen Immobilienmakler, der erfolgreich sein möchte. Linda Bäumer kennt sich bestens mit dem Immobilienmarkt in Rudolstadt aus und ist stets über aktuelle Trends und Entwicklungen informiert. Darüber hinaus verfügt Sie über Kenntnisse in den Bereichen Verkaufs- und Verhandlungstechniken sowie rechtliche und finanzielle Aspekte. Dieses Fachwissen ermöglicht es uns, Sie umfassend zu beraten und Ihnen wertvolle Lösungen anzubieten.u Seite.
        </p>
      </Box>
      <Box>
        <h4>Zuverlässigkeit</h4>
        <p>
        Zuverlässigkeit ist eine Eigenschaft, die ein guter Immobilienmakler mitbringen muss. Selbstverständlich halten wir unsere  Versprechen und Termine stets ein. So stärken wir permanent das Vertrauen und Sie genießen ein starkes Gefühl der Sicherheit.
        </p>
      </Box>
    </Box>
  </Flex>
);

export default SectionAttributes;
