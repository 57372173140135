import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Hide } from "@rebass/hide";

import Hero, { Intro } from "../components/Hero";
import Page from "../components/Page";

import About from "../components/SectionAbout";
import Attributes from "../components/SectionAttributes";
import Testimonials from "../components/SectionTestimonials";
import Services from "../components/SectionServices";
import Locations from "../components/SectionLocations";
import Values from "../components/SectionValues";
import Tile from "../components/Tile";
import { Flex, Box } from "@rebass/grid/emotion";
import ContactCard from "../components/ContactCard";
import PageBlock from "../components/PageBlock";
import { css } from "@emotion/react";
import PageTitle from "../components/PageTitle";
import ImmoweltBadge from "../components/ImmoweltBadge";
import GoogleReviews from "../components/GoogleReviews";
import Team from "../components/SectionTeam";

const Index = ({ data }) => (
  <Page
    header={
      <Hero fluid={data.homeHeroImage.fluid}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={css`
            max-width: 40em;
          `}
        >
          <PageTitle>Immobilienmakler in Rudolstadt</PageTitle>
          <Intro>
            Als professionelle Experten für den Immobilienmarkt in Rudolstadt stehen wir Ihnen mit unserer langjährigen Erfahrung 
            und umfangreichen Kenntnissen als Immobilienmakler zur Seite. Wir unterstützen Sie bei der Suche nach der idealen Immobilieninvestition sowie bei dem optimalen Verkauf Ihrer Immobilie.
          </Intro>
          <Flex alignItems="stretch" flexWrap="nowrap">
            <Hide xsmall>
              <ImmoweltBadge />
            </Hide>
            <GoogleReviews />
          </Flex>
        </Flex>
      </Hero>
    }
  >
    <Helmet
      title="Immobilienmakler in Rudolstadt"
      meta={[
        {
          name: "description",
          content:
            "Wir unterstützen Sie bei der Suche nach der idealen Immobilieninvestition sowie bei dem optimalen Verkauf Ihrer Immobilie.",
        },
      ]}
    />
    <PageBlock>
      <About data={data} />
      <Attributes />
      <Team />
      <Services />

      <Testimonials data={data} />
      <Locations />
      <Tile>
        <Flex justifyContent="center">
          <Box width={[1, null, 1 / 2]}>
            <ContactCard />
          </Box>
        </Flex>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    homeHeroImage: imageSharp(
      fluid: { originalName: { regex: "/home-hero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    founderImage: imageSharp(fluid: { originalName: { regex: "/linda_2/" } }) {
      fluid(maxWidth: 250, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/testimonials/" } }
      sort: { fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          html
        }
      }
    }
  }
`;

export default Index;
