import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import { Link } from "gatsby";

const SectionLocations = () => (
  <Flex justifyContent="center">
    <Box width={[1, 1, 2 / 3, 1 / 2]} my={5} px={3}>
      <h2>Ihr Immobilienmakler in Rudolstadt für Rudolstadt und Umgebung</h2>
      <p>
        Für Immobilienverkäufer oder Suchende in Rudolstadt sind wir der perfekte Immobilienmakler. Denn wir kennen uns mit dem örtlichen Markt hervorragend aus. 
        Mit fundiertem Fachwissen bieten wir maßgeschneiderte Lösungen für Ihre Ziele. Unsere engagierte Arbeitsweise, 
        Zuverlässigkeit und Kundenorientierung stellen Ihre Bedürfnisse in den Mittelpunkt. Vertrauen Sie uns als Ihren lokalen 
        Experten auf dem Weg zu Ihrer Traumimmobilie in Rudolstadt.
      </p>
      <Link to="/immobilienmakler-saalfeld-rudolstadt/"><h3>Immobilienmakler in Saalfeld-Rudolstadt</h3></Link>
      <p>
        Der Landkreis Saalfeld-Rudolstadt zeichnet sich durch eine attraktive Lage und vielfältige Immobilienmöglichkeiten aus. Die Region 
        bietet eine reizvolle Landschaft mit malerischen Orten und historischem Charme, was den Immobilienmarkt hier besonders interessant 
        macht. Ob historische Fachwerkhäuser, moderne Neubauten oder idyllische Landhäuser - der Landkreis Saalfeld-Rudolstadt bietet eine 
        Vielzahl an Immobilienoptionen für unterschiedliche Bedürfnisse und Vorlieben.
      </p>
      <Link to="/immobilienmakler-saalfeld/"><h3>Immobilienmakler in Saalfeld</h3></Link>
      <p>
        Die Stadt Saalfeld ist ein attraktiver Standort mit einer vielfältigen Immobilienlandschaft. Die Stadt besticht durch ihre historische 
        Altstadt und bietet eine reiche Auswahl an Immobilien, darunter liebevoll restaurierte Fachwerkhäuser, moderne Wohnungen und charmante 
        Stadtvillen. Der Immobilienmarkt in Saalfeld bietet somit sowohl für historische Liebhaber als auch für moderne Wohnbedürfnisse eine breite 
        Palette an Möglichkeiten. Die zentrale Lage, umgeben von malerischer Natur und einer lebendigen Infrastruktur, macht Saalfeld zu einem 
        attraktiven Ort zum Leben und Investieren.
      </p>
      <Link to="/immobilienmakler-bad-blankenburg/"><h3>Immobilienmakler in Bad Blankenburg</h3></Link>
      <p>
        Bad Blankenburg ist ein idyllischer Ort mit einem charmant, historischem 
        Stadtkern und einer wunderschönen Umgebung. Der Immobilienmarkt in Bad Blankenburg bietet eine breite Auswahl an Wohnungen, Einfamilienhäusern 
        und Grundstücken, die sowohl den Bedürfnissen von Familien als auch von Naturliebhabern gerecht werden. Mit seinen reizvollen Wohnlagen und 
        der Nähe zu Naherholungsgebieten ist Bad Blankenburg ein attraktiver Ort, um ein Zuhause zu finden oder in Immobilien zu investieren.
      </p>
    </Box>
  </Flex> 
);

export default SectionLocations;

