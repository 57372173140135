import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";
import { LinkButton } from "../Button";
import Card from "../Card";
import ProfileImage from "../ProfileImage";

const SectionAbout = () => (
  <Flex justifyContent="center">
    <Box width={[1, null, 3 / 4]} my={4} p={3}>
      <Card>
        <Flex alignItems="center" flexWrap="wrap" mx={-3}>
          <Box width={[1, 1, 0]} px={[3, 3, 0]}>
            <ProfileImage />
          </Box>
          <Box width={[1, null, 2 / 3]} px={3}>
            <h2>Ihre erfolgreiche Immobilienmaklerin in Rudolstadt</h2>
            <p>
              Ich bin Linda Bäumer, Ihre Immobilienmaklerin in und für Rudolstadt. Dank meiner langjährigen Erfahrung 
              weiß ich genau, was Sie auf Ihrem Weg erwartet. Gerne begleite ich Sie fachkundig und einfühlsam auf Ihrer Immobilienreise.
            </p>
            <p><strong>Was Sie erwarten können:</strong></p>
            <ul>
              <li>Klare und effektive Kommunikation</li>
              <li>Verständnis für Ihre Anforderungen und Sorgen</li>
              <li>Umfangreiches Wissen über den Immobilienmarkt in Rudolstadt</li>
              <li>Einhalten von Versprechen und Terminen</li>
              <li>Aktives Engagementund maßgeschneiderte Lösungen</li>
            </ul>
            <LinkButton to="/kontakt/">Jetzt Kontakt aufnehmen</LinkButton>
          </Box>
          <Box width={[0, 0, 1 / 3]} px={[0, 0, 3]}>
            <ProfileImage />
          </Box>
        </Flex>
      </Card>
    </Box>
  </Flex>
);

export default SectionAbout;
