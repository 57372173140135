import React from "react";

import Card from "../Card";
import { LinkButton } from "../Button";
import { Flex, Box } from "@rebass/grid/emotion";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const Service = styled(Card)`
  margin-bottom: 32px;
`;

const SimpleLink = styled(Link)`
  display: inline-block;
  font-size: 16px;
  padding: 10px 20px;
  opacity: 0.5;
`;

const SectionServices = ({ data, ...props }) => (
  <Flex justifyContent="center">
    <Box width={[1, 1, 2 / 3, 1 / 2]} p={3}>
      <h2>Als Immobilienmakler in Rudolstadt können wir das für Sie tun:</h2>
      <Box mx={[0, 0, -40]}>
        <Service red collapsed>
          <h3>Immobilie verkaufen</h3>
          <p>
            Der Verkauf eines Hauses oder einer Wohnung birgt für Verkäufer
            viele Aufgaben, Herausforderung und manchmal auch Tücken. Als
            erfahrene Maklerin für das Bundesland Thüringen hilft Linda Bäumer
            seit vielen Jahren erfolgreich beim Verkauf von Immobilien im
            Freistaat. Dabei stehen neben dem erfolgreichen Verkauf des Objektes
            die persönlichen Bedürfnisse und Wünsche ihrer Kunden immer im
            Mittelpunkt.
          </p>
          <div>
            <LinkButton to="/immobilie-in-rudolstadt-verkaufen/">
              Mehr zum Immobilienverkauf
            </LinkButton>
            <SimpleLink to="/immobilie-verkaufen-rudolstadt/referenzen">
              Referenzen ansehen
            </SimpleLink>
          </div>
        </Service>
        <Service blue collapsed>
          <h3>Immobilie bewerten</h3>
          <p>
            Bevor eine Immobilie verkauft werden soll, ist es wichtig, den
            aktuellen Wert des Objektes zu kennen. Haben Sie bereits den
            Entschluss gefasst, Ihre Immobilie in Thüringen zu verkaufen? Oder
            möchten Sie sich erst einmal einen groben Überblick über den
            aktuellen Markt verschaffen? Die professionelle Bewertung Ihrer
            Immobilie ist in beiden Fällen unabdingbar.
          </p>
          <div>
            <LinkButton to="/immobilienbewertung-rudolstadt/">
              Jetzt Immobilie kostenlos bewerten
            </LinkButton>
          </div>
        </Service>
        <Service purple collapsed>
          <h3>Immobilie finden</h3>
          <p>
            Zu Hause ist dort, wo man sich am wohlsten fühlt. Viele Menschen
            kaufen nur einmal im Leben eine Immobilie. Es ist also extrem
            wichtig, dass das Komplettpaket stimmt. Schließlich möchten Sie im
            besten Fall ihr restlichen Leben in dieser Immobilie verbringen.
            Erzählen Sie Frau Linda Bäumer von Ihren Wünschen und lassen Sie
            sich bei der Suche nach der perfekten Immobilie tatkräftig
            unterstützen.
          </p>
          <div>
            <LinkButton to="/immobilien-rudolstadt/">Aktuelle Angebote</LinkButton>
          </div>
        </Service>
      </Box>
    </Box>
  </Flex>
);

export default SectionServices;
